exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-doencas-oculares-jsx": () => import("./../../../src/pages/doencas-oculares.jsx" /* webpackChunkName: "component---src-pages-doencas-oculares-jsx" */),
  "component---src-pages-exames-jsx": () => import("./../../../src/pages/exames.jsx" /* webpackChunkName: "component---src-pages-exames-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-sobre-a-clinica-jsx": () => import("./../../../src/pages/sobre-a-clinica.jsx" /* webpackChunkName: "component---src-pages-sobre-a-clinica-jsx" */),
  "component---src-pages-tratamentos-jsx": () => import("./../../../src/pages/tratamentos.jsx" /* webpackChunkName: "component---src-pages-tratamentos-jsx" */)
}

